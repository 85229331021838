import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Analytics_Invites_Vendor from '../views/analytics/vendorinvites.vue';
import Analytics_Store_Signups from '../views/analytics/storesignups.vue';
import Analytics_Store_Sessions from '../views/analytics/sessionregistration.vue';
import Analytics_Master_Attendee_List from '../views/analytics/masterlistattendees.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/analytics/invites/vendor',
        name: "VendorInvites",
        component: Analytics_Invites_Vendor
    },
    {
        path: '/analytics/store/signups',
        name: "StoreSignups",
        component: Analytics_Store_Signups
    },
    {
        path: '/analytics/store/sessions',
        name: "StoreSessions",
        component: Analytics_Store_Sessions
    },
    {
        path: '/analytics/masterlist',
        name: "MasterList",
        component: Analytics_Master_Attendee_List
    }
];

const router = new VueRouter({
    routes
});

export default router;