<template>
    <div v-if="!submitting">
        <nav class="navbar navbar-expand-sm bg-pcvt sticky-top"
             style="border-radius: 0px 0px 4px 4px">
            <div class="container-fluid">
                <div class="navbar-brand">
                    Store Registrations
                </div>
                <div class="">
                    <div class="navbar-nav">
                        <a @click="exportRegistrations()"
                            role="button"
                            class="nav-link pe-auto">
                            Export Registrations
                        </a>
                        <a @click="build"
                            role="button"
                            class="nav-link pe-auto">
                            Refresh
                        </a>
                    </div>
                </div>
            </div>
        </nav>

        <h5 class="text-center mt-3 mb-3">
            Last refreshed: {{lastRefreshTime | moment('LL, LT')}}
        </h5>


        <div class="row sticky-top bg-white">
            <div class="col-12">
                
                <div class="row mt-2 mb-2">
                    <div class="col text-center">
                        <div class="btn-group">
                            <button class="btn btn-secondary"
                                    :class="{'btn-primary': mode == 'registrations'}"
                                    @click="mode = 'registrations'">
                                Registrations ({{ Object.keys(details.individualResponses).length }})
                            </button>
                            <button class="btn btn-secondary"
                                    @click="mode = 'sessions'"
                                    :class="{'btn-primary': mode == 'sessions'}">
                                Sessions
                            </button>
                        </div>
                    </div>
                </div>

                <!-- <h6>Details</h6> -->

                <template v-if="mode == 'registrations'">
                    <div>
                        <table class="table table-hover table-responsive">
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Store #
                                    </th>
                                    <th>
                                        Role
                                    </th>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Session 1
                                    </th>
                                    <th>
                                        Session 2
                                    </th>
                                    <th>
                                        Session 3
                                    </th>
                                    <th>
                                        Session 4
                                    </th>
                                    <th>
                                        Attending Thursday
                                    </th>
                                    <th>
                                        Attending Friday
                                    </th>
                                    <th>
                                        Dietary Restrictions
                                    </th>
                                    <th>
                                        Details
                                    </th>
                                    <th>
                                        Reg Date
                                    </th>
                                    <th>
                                        Tractus Code
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, code) in details.individualResponses">
                                    <td>
                                        {{ details.attendees[code].firstName }} {{ details.attendees[code].lastName }} 
                                    </td>
                                    <td>
                                        {{ details.attendees[code].storeNumber }} 
                                    </td>
                                    <td>
                                        {{ details.attendees[code].categoryTitles[0] }} 
                                    </td>
                                    <td>
                                        {{ details.attendees[code].title }} 
                                    </td>
                                    <td v-for="response in item">
                                        {{ response }}
                                    </td>       
                                    <td>
                                        {{ details.attendees[code].registrationDateTime | moment('LL, LT') }}
                                    </td>       
                                    <td>
                                        {{ code }}
                                    </td>
                                    <td>
                                        <a :href="`https://survey.tractus.ca/#/pp/PCVT2023/7458C5F268724DFEBC8A50DB8B4418D4?r=${details.attendees[code].registrationPollId}`"
                                           target="_blank">
                                            Edit
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else-if="mode == 'sessions'">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>
                                            Category
                                        </th>
                                        <th>
                                            Time
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            # Registered
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="session in details.sessions">
                                        <td>
                                            {{ session.category }}
                                        </td>   
                                        <td>
                                            {{ session.startDateTime | moment('h:mm a') }}
                                        </td>
                                        <td>
                                            {{ session.name }}
                                        </td>
                                        <td>
                                            {{ session.attendees.length }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-9">

                        </div>
                    </div>
                </template>
                <!--<div>
                    <table class="table table-hover table-responsive">
                        <thead>
                            <tr>
                            <th>
                                Store #
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Province
                            </th>
                            <th>
                                Parent
                            </th>
                            <th>
                                # Staff Registered
                            </th>
                            <th>
                                Registered
                            </th>
                            <th>
                                Login E-Mail
                            </th>
                            <th>
                                Login Link
                            </th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-for="storeResult in listItems">
                                <tr @click="storeResult.selected = !storeResult.selected">
                                    <td>
                                        {{storeResult.storeNumber}}
                                    </td>
                                    <td>
                                        {{storeResult.storeName}}
                                    </td>
                                    <td>
                                        {{storeResult.province}}
                                    </td>
                                    <td>
                                        {{storeResult.parentAccount}}
                                    </td>
                                    <td>
                                        {{storeResult.attendees.length}}
                                    </td>
                                    <td>
                                        {{storeResult.attendees.length > 0 ? "✅" : "❌"}}
                                    </td>
                                    <td>
                                        {{storeResult.email}}
                                    </td>
                                    <td>
                                        <a @click.stop
                                           v-if="!storeResult.isParentAccount"
                                            :href="buildLoginEmail(storeResult)"
                                            target="_blank">
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="storeResult.selected">
                                    <td class="align-middle text-right fw-bold">
                                        <div>
                                            Registered Staff
                                        </div>
                                        <div class="mt-2">
                                            <button class="btn btn-sm btn-primary"
                                                    type="button"
                                                    @click="onEdit(storeResult)">
                                                Edit
                                            </button>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <table class="table me-2 mt-2 mb-2 table-light border">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>E-Mail</th>
                                                <th>Role</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attendee in storeResult.attendees">
                                                <td>{{attendee.firstName}} {{attendee.lastName}}</td>
                                                <td>{{attendee.primaryEmail}}</td>
                                                <td> <span v-for="role in attendee.categoryTitles">{{role}}</span> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>-->
            </div>
        </div>

    </div>
    <div v-else class="d-flex h-100 w-100 justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5 class="mt-3">Loading, please wait...</h5>
      </div>
</template>
<script>
import Modal from './storeRegistrationEditModal.vue'
import Vue from 'vue'

export default {
    components:{
        Modal
    },
    data() {
        return {
            details: null,
            submitting: true,
            lastRefreshTime: null,
            errorLoad: false,
            searchTerm: '',
            mode: 'registrations'
        };
    },

    watch:{

    },

    computed: {

    },

    created() {
        this.build()
    },

    beforeDestroy() {
    },

    methods: {        
        
        buildFormUrl(id) {
            let url = `https://survey.tractus.ca/#/pp/PCVT2023/7458C5F268724DFEBC8A50DB8B4418D4?r=${id}`;

            return url;
        },


        exportRegistrations() {
            let csvFile = 'Name,Store #,Role,Title,Session 1,Session 2,Session 3,Session 4,Thursday,Friday,Dietary Restrictions,Dietary Details,Registered On,Tractus Code,Form URL\n';


            Object.keys(this.details.individualResponses).forEach(code => {
                let attendee = this.details.attendees[code];

                csvFile += `"${attendee.firstName?.replace('"', '')} ${attendee.lastName?.replace('"', '')}","${attendee.storeNumber || ''}","${attendee.categoryTitles[0] || ''}","${attendee.title?.replace('"', '') || ''}",`;

                let response = this.details.individualResponses[code];

                response.forEach(r => {
                    csvFile += `"${r?.replace('"', '') || ''}",`;
                });

                csvFile += `"${this.$moment(attendee.registrationDateTime).format('LL, LT')}","${code}","${this.buildFormUrl(attendee.registrationPollId)}"`
                csvFile += "\r\n";
            });

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);

                let now = new Date();
                let exportFileName = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;

                link.setAttribute("download", `export_attendee_reg_list_${exportFileName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        },

        async build() {
            this.submitting = true;
            this.allStoreCode = [];
            let apiKey = localStorage.getItem('aak') || this.$route.query.aak;

            if(!apiKey) {
                this.$router.replace({path: '/'});
                return;
            }

            const apiRoutePrefix = process.env.VUE_APP_API_URL;

            const route = `${apiRoutePrefix}/api/stats/invites/store/attendees/details?aak=${apiKey}`;

            let responseRaw = await fetch(route);

            if(!responseRaw.ok) {
                localStorage.clear();
                alert("We're sorry. We couldn't load the analytics. Please try again later.")
                //this.$router.replace({path: '/'});
                return;
            }

            localStorage.setItem('aak', apiKey);

            let response = await responseRaw.json();
            // console.log(response);
           

            this.details = response;
            this.lastRefreshTime = new Date();
            this.submitting = false;
        },
    }
}
</script>
