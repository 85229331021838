<template>
    <div v-if="!submitting">
        <nav class="navbar navbar-expand-sm bg-pcvt sticky-top"
             style="border-radius: 0px 0px 4px 4px">
            <div class="container-fluid">
                <div class="navbar-brand">
                    Store Registrations
                </div>
                <div class="">
                    <div class="navbar-nav">
                        <a @click="addStore"
                            role="button"
                            class="nav-link pe-auto">
                            Add...
                        </a>
                        <a @click="exportListToCsv('logins')"
                            role="button"
                            class="nav-link pe-auto">
                            Export Store Login Links
                        </a>
                        <a @click="exportAttendees()"
                            role="button"
                            class="nav-link pe-auto">
                            Export Attendees
                        </a>
                        <a @click="build"
                            role="button"
                            class="nav-link pe-auto">
                            Refresh
                        </a>
                    </div>
                </div>
            </div>
        </nav>

        <h5 class="text-center mt-3 mb-3">
            Last refreshed: {{lastRefreshTime | moment('LL, LT')}}
        </h5>


        <div class="row sticky-top bg-white">
            <div class="col-3">
                <div class="sticky-top">
                    <h6>Summary</h6>

                    <table class="sticky-top table table-hover table-responsive">
                        <tbody>
                            <template v-for="stat in summaryStats">
                                <tr>
                                    <th>
                                        {{stat.Label}}
                                    </th>
                                    <td>
                                        {{stat.Value}}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-9">
                
                <div class="row mt-2 mb-2">
                    <div class="col"><input class="form-control" placeholder="Search..." v-model="searchTerm"></div>
                    <div class="col-auto">
                        <div class="d-flex h-100 align-items-center">
                        <ul class="pagination m-0 me-1">

                        <li class="page-item">
                            <a class="page-link" role="button" @click="resetToStart">
                                «
                            </a>
                        </li>
                        
                        <li class="page-item">
                            <a class="page-link" role="button" @click="changePage(-1)">
                                &lt;
                            </a>
                        </li>
                        
                        </ul>
                        <div>
                            {{pageLower}} - {{pageUpper}} of {{pageTotalResults}}
                        </div>
                        <div class="ms-1 me-1">
                        <select class="form-select" v-model="maxItemsDisplay">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="9999">All</option>
                        </select>
                        </div>
                    <ul class="pagination m-0 ms-1">
                        <li class="page-item">
                            <a class="page-link" role="button" @click="changePage(1)">
                                &gt;
                            </a>
                        </li>

                        <li class="page-item">
                            <a class="page-link" role="button" @click="resetToEnd">»</a>
                        </li>
                        
                        
                        </ul>    
                        </div>
                    </div>
                </div>

                <!-- <h6>Details</h6> -->

                <div>
                    <table class="table table-hover table-responsive">
                        <thead>
                            <tr>
                            <th>
                                Store #
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Province
                            </th>
                            <th>
                                Parent
                            </th>
                            <th>
                                # Staff Registered
                            </th>
                            <th>
                                Registered
                            </th>
                            <th>
                                Login E-Mail
                            </th>
                            <!-- <th>
                                Last Sign-In
                            </th> -->
                            <th>
                                Login Link
                            </th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-for="storeResult in listItems">
                                <tr @click="storeResult.selected = !storeResult.selected">
                                    <td>
                                        {{storeResult.storeNumber}}
                                    </td>
                                    <td>
                                        {{storeResult.storeName}}
                                    </td>
                                    <td>
                                        {{storeResult.province}}
                                    </td>
                                    <td>
                                        {{storeResult.parentAccount}}
                                    </td>
                                    <td>
                                        {{storeResult.attendees.length}}
                                    </td>
                                    <td>
                                        {{storeResult.attendees.length > 0 ? "✅" : "❌"}}
                                    </td>
                                    <td>
                                        {{storeResult.email}}
                                    </td>
                                    <!-- <td>
                                        {{storeResult.lastSignIn}}
                                    </td> -->
                                    <td>
                                        <a @click.stop
                                           v-if="!storeResult.isParentAccount"
                                            :href="buildLoginEmail(storeResult)"
                                            target="_blank">
                                            <!-- <template v-if="item.regFormUrl">
                                                Form
                                            </template>
                                            <template v-else> -->
                                                Sign In
                                            <!-- </template> -->
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="storeResult.selected">
                                    <td class="align-middle text-right fw-bold">
                                        <div>
                                            Registered Staff
                                        </div>
                                        <div class="mt-2">
                                            <button class="btn btn-sm btn-primary"
                                                    type="button"
                                                    @click="onEdit(storeResult)">
                                                Edit
                                            </button>
                                        </div>
                                    </td>
                                    <td colspan="6">
                                        <table class="table me-2 mt-2 mb-2 table-light border">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>E-Mail</th>
                                                <th>Role</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attendee in storeResult.attendees">
                                                <td>{{attendee.firstName}} {{attendee.lastName}}</td>
                                                <td>{{attendee.primaryEmail}}</td>
                                                <td> <span v-for="role in attendee.categoryTitles">{{role}}</span> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <modal :selectedStore="selectedStore"
                :allStoreCode="allStoreCode"
                :editable="isEditable"
                v-if="selectedStore"
                @close="selectedStore = null"
                @save="onSave"
                @delete="onDelete">

        </modal>

    </div>
    <div v-else class="d-flex h-100 w-100 justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5 class="mt-3">Loading, please wait...</h5>
      </div>
</template>
<script>

import Modal from './storeRegistrationEditModal.vue'
import Vue from 'vue'

export default {
    components:{
        Modal
    },
    data() {
        return {
            details: null,
            submitting: true,
            lastRefreshTime: null,
            errorLoad: false,
            selectedInvite: null,
            searchTerm: null,
            maxItemsDisplay: 50,
            page: 0,
            selectedStore: null,
            isEditable: false,
            allStoreCode: [],
        };
    },

    watch:{
        maxItemsDisplay(){
            this.page = 0;
        },
        searchTerm(){
            this.page = 0;
        },
    },

    computed: {
        pageLower(){
            return Number(this.maxItemsDisplay * this.page) + Number(1);
        },
        pageUpper(){
            return Math.min(this.filteredItems.length,(this.pageLower + Number(this.maxItemsDisplay) - Number(1)));
        },
        pageTotalResults(){
            return this.filteredItems.length;
        },
         listItems() {

            let items = this.filteredItems;

            let skipAmount = this.page * Number(this.maxItemsDisplay);
            let takeAmount = Number(this.maxItemsDisplay);

            items = items.slice(skipAmount, skipAmount + takeAmount);

            return items;
        },
        filteredItems(){
            let items = this.details;
            if(this.searchTerm){
                let searchTerm = new RegExp(this.searchTerm, 'i');

                //StoreResult(string storeName, string email, string province, string parentAccount, string storeNumber, StoreAttendeeResult[] attendees, DateTime? lastSignIn);

                items = items.filter(s => 
                    searchTerm.test(s.storeNumber) 
                    || searchTerm.test(s.storeName)
                    || searchTerm.test(s.email)
                    || searchTerm.test(s.parentAccount));
            }

            return items;
        },

        // appUrl() {
        //     const appUrl = process.env.VUE_APP_PWA_URL;
        //     return appUrl;
        // },

        summaryStats() {

            // { "OWNERPHARM", "Owner/Pharmacist" },
            // { "LICTECH", "Licensed Technician" },
            // { "FSM", "Front Store Manager" },
            // { "OTHER", "Store Personnel/Other" }

            const attendees = this.details.map(x => x.attendees).flat();
            
            const totalOwner = attendees.filter(y => y.categoryCodes.includes("OWNERPHARM")).length;
            const totalLicensedTechnicians = attendees.filter(y => y.categoryCodes.includes("LICTECH") ).length;
            const totalFSMS = attendees.filter(y => y.categoryCodes.includes("FSM") ).length;
            const totalOthers = attendees.filter(y => y.categoryCodes.includes("OTHER") ).length;
            const totalRegistered = attendees.length;
            const totalStores = this.details.length;

            return [
                {
                    Label: "# Owner/Pharmacists",
                    Value: totalOwner
                },
                {
                    Label: "# Licensed Technicians",
                    Value: totalLicensedTechnicians
                },
                {
                    Label: "# FSMs",
                    Value: totalFSMS
                },
                {
                    Label: "# Other",
                    Value: totalOthers
                },
                {
                    Label: "Total # Registered",
                    Value: totalRegistered
                },
                {
                    Label: "# Stores",
                    Value: totalStores
                }
            ]
        },
    },

    created() {
        this.build()
    },

    beforeDestroy() {
    },

    methods: {
        addStore(){
            this.selectedStore = {
                'email': '',
                'parentAccount':'',
                'province':'',
                'storeName':'',
                'storeNumber':'',
            };
            this.isEditable = true;
        },
        onDelete(){
            this.isEditable = false;
            this.selectedStore = null;
            this.build();
        },
        onSave(){
            this.isEditable = false;
            this.selectedStore = null;
            this.build();
        },
        onEdit(storeResult){
            this.selectedStore = storeResult;
        },
        resetToStart(){
            this.page = 0;
        },
        resetToEnd(){
            this.page = Math.floor(this.filteredItems.length/this.maxItemsDisplay);
        },
        changePage(direction){
            let skipAmount = this.page * Number(this.maxItemsDisplay);
            let takeAmount = Number(this.maxItemsDisplay);
            if(direction == -1 && this.page == 0){
                return;
            }
            else if(direction == 1 && this.filteredItems.length < Number(this.maxItemsDisplay)){
                return;
            }
            else if(direction == 1 && this.filteredItems.length < (takeAmount + skipAmount)){
                return;
            }
            else{
                this.page += direction;
            }
        },
        registrationUrl(storeNumber) {
            const regUrl = process.env.VUE_APP_REG_URL;
            
            const finalLink = `${regUrl}?qr=${encodeURIComponent(`0:${storeNumber}`)}`

            return finalLink;
        },

        buildLoginEmail(item) {
            let url = `${window.location.protocol}//${window.location.host}/#/?u=${encodeURIComponent(item.email)}&p=${encodeURIComponent('UGNyeHRyYWRlc2hvdzIwMjM=')}`;

            return url;
        },

        buildFormUrl(id) {
            let url = `https://survey.tractus.ca/#/pp/PCVT2023/7458C5F268724DFEBC8A50DB8B4418D4?r=${id}`;

            return url;
        },

        exportAttendees() {
            let csvFile = 'Store Name,Store #,Province,Parent Store,Attendee Name,E-Mail,Role,Title,Registered On,Tractus Code,Form URL\n';

            let items = this.details.filter(x => x.attendees.length > 0);
            console.log(items)

            for(let i = 0; i < items.length; i++) {
                let item = items[i];

                item.attendees.forEach(a => {
                    csvFile += `"${item.storeName}","${item.storeNumber}","${item.province}","${item.parentAccount}","${a.firstName} ${a.lastName}","${a.primaryEmail}","${a.categoryTitles[0]}","${a.title}","${this.$moment(a.registrationDateTime).format("LL, LT")}","${a.code}","${this.buildFormUrl(a.registrationPollId)}"\r`;
                });
            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);

                let now = new Date();
                let exportFileName = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;

                link.setAttribute("download", `export_store_attendee_list_${exportFileName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        },
 
        exportListToCsv(mode) {

            if(!mode) {
                mode = 'all';
            }

            let csvFile = 'Store Name,Store #,Province,Parent Store,# Registered Staff,Registered,Login Email,Sign In Link\n';

            let items = this.details;

            for(let i = 0; i < items.length; i++) {
                let item = items[i];

                csvFile += `"${item.storeName}","${item.storeNumber}","${item.province}","${item.parentAccount}",${item.attendees.length},"${item.attendees.length > 0 ? 'Yes' : 'No'}","${item.email}","${this.buildLoginEmail(item)}"\r`;

            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);

                let now = new Date();
                let exportFileName = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;

                link.setAttribute("download", `export_store_logins_${exportFileName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },




        // },

        // onSave(){
        //     this.selectedInvite = null;
        //     this.build();
        // },
        // cloneObject(source) {
        //     if(source) {
        //         return JSON.parse(JSON.stringify(source));
        //     }

        //     return null;
        // },
        // onVendorClick(item){
        //     this.selectedInvite = this.cloneObject(item);
        // },
        // getSponsorCategory(code) {
        //     switch(code){
        //         case '1PLAT':
        //             return 'Platinum';
        //         case '2GOLD':
        //             return 'Gold';
        //         case '3SILV':
        //             return 'Silver';
        //         case '4BRONZE':
        //             return 'Bronze';
        //         default:
        //             return code;
        //     }
        // },

        // buildRegInviteUrl(item) {
        //     const regUrl = process.env.VUE_APP_VREG_URL;

        //     if(item.regFormUrl) {

        //         const finalLink = `${regUrl}?r=${item.regFormUrl}`

        //         return finalLink;

        //     } else {

        //         const finalLink = `${regUrl}?qrr=${encodeURIComponent(`528CD0E0610D4B5EB51722A693639D01:${item.code}`)},${encodeURIComponent(`4DCC2BFF801E485AAA363F6ACE2B8D9D:${item.name}`)}`

        //         return finalLink;

        //     }
            
        // },

        async build() {
            this.submitting = true;
            this.allStoreCode = [];
            let apiKey = localStorage.getItem('aak') || this.$route.query.aak;

            if(!apiKey) {
                this.$router.replace({path: '/'});
                return;
            }

            const apiRoutePrefix = process.env.VUE_APP_API_URL;

            const route = `${apiRoutePrefix}/api/stats/invites/store/attendees?aak=${apiKey}`;

            let responseRaw = await fetch(route);

            if(!responseRaw.ok) {
                localStorage.clear();
                alert("We're sorry. We couldn't load the analytics. Please try again later.")
                this.$router.replace({path: '/'});
                return;
            }

            localStorage.setItem('aak', apiKey);

            let response = await responseRaw.json();
            // console.log(response);
           
           response.forEach(element => {
               Vue.set(element, 'selected', false);
               this.allStoreCode.push(element.storeNumber);
           });

            this.details = response;
            this.lastRefreshTime = new Date();
            this.submitting = false;
        },
    }
}
</script>
