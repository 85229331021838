// https://vuejs.org/examples/#modal
<script>
export default {
    props: ['selectedInvite'],

    data(){
        return{
            prizeWheelValues:[
                {Name: "$50, $75, $150", Code: "1"},
                {Name: "$100, $200, $300", Code: "2"},
                {Name: "$200, $300, $500", Code: "3"},
                {Name: "$400, $600, $1,000", Code: "4"},
            ],
            sponsorCategories:[
                {Name:"Platinum",Code:"1PLAT"},
                {Name:"Gold",Code:"2GOLD"},
                {Name:"Silver",Code:"3SILV"},
                {Name:"Bronze",Code:"4BRONZE"},
            ],
            error: null,
        }
    },
    methods:{
      async onSave(){
          console.log(this.selectedInvite);
          let apiKey = localStorage.getItem('aak') || this.$route.query.aak;
          const apiRoutePrefix = process.env.VUE_APP_API_URL;
          const route = `${apiRoutePrefix}/api/stats/invites/vendors?aak=${apiKey}`;
          var headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          };

          try{
              let responseRaw = await fetch(route,{
                  method: 'Post',
                  mode: 'cors',
                  headers: headers,
                  body: JSON.stringify(this.selectedInvite),
              });

                if(!responseRaw.ok) {
                    this.error = r.statusText;
                    return;
                }
                else{
                  this.$emit('save');
                }

            }
            catch(ex){
              this.error = "Error";
            }
        },
    },
}
</script>

<template>
  <Transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <!-- <div class="modal-header">
            <slot name="header">default header</slot>
          </div> -->
          <div class="modal-body">
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedInvite.name">
                <label>
                    Name
                </label>
              </div>
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedInvite.boothNumbers">
                <label>
                    Booth #
                </label>
              </div>              <div class="form-floating mt-2">
                    <select v-model="selectedInvite.sponsorCategory"
                            class="form-select">
                        <option selected disabled hidden value="">Regular Exhibitor</option>
                        <option v-for="sponsorCategory in sponsorCategories" 
                                :value="sponsorCategory.Code">{{sponsorCategory.Name}}</option>
                    </select>
                    <label>
                        Sponsor Category
                    </label>
            </div>



          </div>
          <div class="modal-footer">
                <div class="alert alert-danger" v-if="error">{{error}}</div>
                <button
                  class="modal-default-button"
                  @click="onSave"
                >Save</button>
                <button
                  class="modal-default-button"
                  @click="$emit('close')"
                >Cancel</button>
                
              </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>