<template>
    <div class="tractus-badge"
         :style="badgeStyle">

        <div v-for="element in elements"
             :key="element.Code"
             class="badge-element"
             :style="computeElementStyle(element)"
             @mousedown="onElementMouseDown($event, element)"
             @mouseup="onElementMouseUp($event, element)"
             @mousemove="onElementMouseMove($event, element)">

            <vue-markdown :source="element.Output" 
                          v-if="element.Type == 'text'"
                          class="vmText"
                          
                          :style="{ color: element.Color.hex, textTransform: element.Transform, fontWeight: element.FontWeight, fontSize: element.Size + 'px', textAlign: element.TAlign, fontFamily: element.FontFamily }">
            </vue-markdown>
            <barcode mode="qrcode" 
                     v-bind:code="element.Output" 
                     v-bind:padding="0"
                     v-else-if="element.Type == 'barcode'">
            </barcode>
            <img v-else-if="element.Type == 'img'" 
                 v-bind:src="element.filename"
                 height="100%" 
                 width="100%">

        </div>

        <div class="inner-badge-margin"
             :class="{'hide-on-print-preview': printPreview}"
             :style="innerMarginStyle">

        </div>

        <div class="inner-badge-guide x"
             v-show="showXCenter">

        </div>
        <div class="inner-badge-guide y"
             v-show="showYCenter">

        </div>
    </div>
</template>
<style>
.vmText > p {
    margin-bottom: 0 !important;
    display: block;
}

.vmText span {
    display: block;
}

.badgePreview {
    outline: 1px dashed black;
}

.vmText > p > strong > a {
    color: black;
}

.tractus-badge-page {
    outline: 1px dashed blue;
    position: relative;
}

.tractus-badge {
    outline: 1px dashed gray;
    position: relative;
}

.tractus-badge-back {
    float: right;
}

.tractus-badge-front {
    float: left;
}

.cropMarkV {
    border-left:1px solid black;
    height: 0.2in;
    width:1px;
    position:absolute;
}

.cropMarkV1 {
    border-left:1px dashed magenta;
    height: 0.3in;
    width:1px;
    position:absolute;
}


.cropMarkH {
    border-top:1px solid black;
    height: 1px;
    width:0.2in;
    position:absolute;
}
.cropMarkH1 {
    border-top:1px dashed magenta;
    height: 1px;
    width:0.5in;
    position:absolute;
}

.extraCodeBg {
    background: white !important;
    color: white !important;
}

hr {
    display: none;
}

.inner-badge-guide {
    position: absolute;
}

.inner-badge-guide.x {
    left: 50%;
    top: 0;
    height: 100%;
    border-left: 1px solid red;
}

.inner-badge-guide.y {
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1px solid red;
}

.hide-on-print-preview {
    display: none !important;
}

.tractus-badge {
    overflow: hidden;
}

.badge-element {
    position: absolute;
    display: flex;
}

.active-element {
    outline: 1px dashed red;
}

div.nobr > br {
    display: none;
}

.inner-badge-margin {
    pointer-events: none;
    display: inline;
    position: absolute;
    outline: 1px dashed #a0a1a2;
}

.vmText > p {
    margin-bottom: 0 !important;
}

.badgePreview {
    outline: 1px dashed black;
}

strong {
    font-weight: 800;
}

.vmText > p > strong > a {
    color: black;
}
.inner-badge-guide {
    position: absolute;
}

.inner-badge-guide.x {
    left: 50%;
    top: 0;
    height: 100%;
    border-left: 1px solid red;
}

.inner-badge-guide.y {
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1px solid red;
}

.hide-on-print-preview {
    display: none !important;
}

.tractus-badge {
    overflow: hidden;
}

.badge-element {
    position: absolute;
    display: flex;
}

.active-element {
    outline: 1px dashed red;
}

div.nobr > br {
    display: none;
}

.inner-badge-margin {
    pointer-events: none;
    display: inline;
    position: absolute;
    outline: 1px dashed #a0a1a2;
}
</style>
<script>
import Vue from 'vue';
import Barcode from './barcode.vue'

export default {
    props: [
        'workspace', 
        'printPreview', 
        'side',
        'selectedElement',
        'snap',
        'inputContent',
        "roleColor",
    ],

    data() {
        return {
            dragData: {
                initX: 0,
                initY: 0,
                initMouseX: 0,
                initMouseY: 0,
                drag: false,
                element: null,
            },

            showXCenter: false,
            showYCenter: false,
        }
    },

    watch: {
        inputContent: {
            handler() {
                this.updateElementDisplay();
            },
            deep: true
        }
    },

    mounted() {
        this.updateElementDisplay();
    },
    
    methods: {
        updateElementDisplay() {
            if(!this.workspace) {
                return;
            }

            let inputContent = this.inputContent;
            console.log(this.workspace)
            
            let side = this.workspace.Document[this.side];

            for(let i = 0; i < side.length; i++) {
                let x = side[i];

                Vue.set(x, 'Output', '');

                if(x.Type == 'text') {
                    x.Output = x.Text;
                } else if(x.Type == 'barcode') {
                    x.Output = x.Barcode
                }

                if(inputContent && x.Output && (x.Type == 'text' || x.Type == 'barcode')) {
                    x.Output = this.stringReplace(x.Output, inputContent);
                }
            }



        },

        stringReplace: function(value, attendee) {
            
            if(value.indexOf("{{") == -1) {
                return value;
            }

            let keys = Object.keys(attendee);

            let toReturn = value;

            keys.forEach(k => {
                let replaceVal = attendee[k];
                
                if(!replaceVal || typeof replaceVal !== 'string') 
                    replaceVal = '&nbsp;';
                    
                replaceVal = replaceVal.trim();

                if(replaceVal.length == 0 || replaceVal == '&nbsp;') {
                    replaceVal = `[empty - ${k}]`
                }

                toReturn = toReturn.replace(`{{${k}}}`, replaceVal)
            });

            keys = Object.keys(attendee.contactInfo);
            console.log("Keys 2");

            keys.forEach(k => {
                let replaceVal = attendee.contactInfo[k];
                console.log(k, replaceVal, attendee.contactInfo)
                if(!replaceVal || typeof replaceVal !== 'string') 
                    replaceVal = '';
                    
                replaceVal = replaceVal.trim();

                if(replaceVal.length == 0 || replaceVal == '&nbsp;') {
                    replaceVal = `[empty - ${k}]`
                }

                toReturn = toReturn.replace(`{{${k}}}`, replaceVal)
            });

            return toReturn.trim();
        },

        onElementMouseDown(e, element) {
            this.$emit('select-element', element);

            this.dragData.drag = true;
            this.dragData.element = element;
            this.dragData.initX = parseFloat(element.Position.x);
            this.dragData.initY = parseFloat(element.Position.y);

            this.dragData.initMouseX = e.clientX;
            this.dragData.initMouseY = e.clientY;
            e.preventDefault();
        },

        onElementMouseMove(e, element) {
            e.preventDefault();
            if(!this.dragData.element
                || !this.selectedElement
                || this.dragData.element != this.selectedElement
                || element != this.selectedElement) {
                return;
            }

            let newX = this.dragData.initX - (this.dragData.initMouseX - e.clientX) / 96.0;
            let newY = this.dragData.initY - (this.dragData.initMouseY - e.clientY) / 96.0;

            newX = this._round(newX, 0.05);
            newY = this._round(newY, 0.05);

            this.dragData.element.Position.x = newX;
            this.dragData.element.Position.y = newY;

            let midpointHeight = element.Position.y + (element.Position.h / 2.0);
            let midpointWidth = element.Position.x + (element.Position.w / 2.0);

            let midpointDocHeight = parseFloat(this.workspace.Document.BadgeSize.Height / 2.0) 
                + parseFloat(this.workspace.Document.BadgeSize.Margin);

            let midpointDocWidth = parseFloat(this.workspace.Document.BadgeSize.Width / 2.0) 
                + parseFloat(this.workspace.Document.BadgeSize.Margin);

            this.showXCenter = midpointDocWidth == midpointWidth;
            this.showYCenter = midpointDocHeight == midpointHeight;

            console.log(`${midpointWidth}, ${midpointHeight} vs. ${midpointDocWidth}, ${midpointDocHeight}`);

            console.log(element);
            console.log(this.workspace);
        },

        _round(number, precision) {
            let step = precision || 1.0;
            let prec = 1.0 / step;

            let result = Math.round(number * prec) / prec;

            return result;
        },

        onElementMouseUp(e, element) {
            e.preventDefault();
            this.dragData.drag = false;
            this.dragData.element = null;
            this.showXCenter = false;
            this.showYCenter = false;
        },

        computeElementStyle(element) {
            let width = parseFloat(element.Position.w);
            let height = parseFloat(element.Position.h);

            let x = parseFloat(element.Position.x);
            let y = parseFloat(element.Position.y);
            let z = parseInt(element.Position.z) || 0;

            let borderRadius = `${element.Corners.left}in ${element.Corners.top}in ${element.Corners.right}in ${element.Corners.bottom}in`;

            let bg = `rgba(${element.Background.rgba.r},${element.Background.rgba.g},${element.Background.rgba.b},${element.Background.rgba.a})`;

            let justify = element.Type == 'text' ? element.Align : 'center';
            let align = element.Type == 'text' ? element.VAlign: 'center';

            let pad = parseFloat(element.Padding);
             
            /*

            padding: `${element.Padding}in`, 
            background: 'rgba(' + element.Background.rgba.r + ',' + element.Background.rgba.g + ',' + element.Background.rgba.b + ',' + element.Background.rgba.a + ')', 
            justifyContent: element.Type == 'text' ? element.Align : 'center', 
            alignItems: element.Type == 'text' ? element.VAlign : 'center', 
            display: 'flex'

            */

            if(element.Code == '55423C5D12D84A889EFF8D8BECF77737') {
                bg = this.roleColor;
            }

            return `padding: ${pad}in; background: ${bg}; justify-content: ${justify}; align-items: ${align}; width: ${width}in; height: ${height}in; left: ${x}in; top: ${y}in; z-index: ${z}; border-radius: ${borderRadius};`;
        }
    },

    computed: {
        elements() {
            return this.side == 'Front'
                ? this.workspace.Document.Front
                : this.workspace.Document.Back;
        },

        innerMarginStyle() {
            let workspace = this.workspace;
            let width = (parseFloat(workspace.Document.BadgeSize.Width));
            let height = (parseFloat(workspace.Document.BadgeSize.Height));
            let left = parseFloat(workspace.Document.BadgeSize.Margin);

            return `width: ${width}in; height: ${height}in; left: ${left}in; top: ${left}in`;
        },

        badgeStyle() {
            let workspace = this.workspace;
            let width = (parseFloat(workspace.Document.BadgeSize.Width) + parseFloat(workspace.Document.BadgeSize.Margin) * 2);
            let height = (parseFloat(workspace.Document.BadgeSize.Height) + parseFloat(workspace.Document.BadgeSize.Margin) * 2);

            return `width: ${width}in; height: ${height}in`;
        }
    }
}
</script>