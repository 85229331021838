// https://vuejs.org/examples/#modal
<script>
export default {
    props: ['selectedStore','editable','allStoreCode'],

    data(){
        return{
            error: null,
        }
    },
    methods:{
      async onSave(){
          let apiKey = localStorage.getItem('aak') || this.$route.query.aak;
          const apiRoutePrefix = process.env.VUE_APP_API_URL;
          const saveRoute = `${apiRoutePrefix}/api/add?aak=${apiKey}`;

          if(this.editable){
            if(this.allStoreCode.includes(this.selectedStore.storeNumber)){
              this.error = "This store already exists";
              return;
            }
          }

          // 
          // 'Password': `tradeshow${this.selectedStore.storeNumber}`,
          var toSave = [{
            'UserName': this.selectedStore.email,
            'Password': (this.editable) ? this.selectedStore.password : null,
            'PhysicalProvince': this.selectedStore.province,
            'ParentAccount': this.selectedStore.parentAccount,
            'PharmacyName': this.selectedStore.storeName,
            'StoreNumber': this.selectedStore.storeNumber,
            'IsParentAccount': this.selectedStore.isParentAccount
          }];

          var headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          };

          try{
              let responseRaw = await fetch(saveRoute,{
                  method: 'Post',
                  mode: 'cors',
                  headers: headers,
                  body: JSON.stringify(toSave),
              });

                if(!responseRaw.ok) {
                    this.error = r.statusText;
                    return;
                }
                else{
                  this.$emit('save');
                }

            }
            catch(ex){
              this.error = "Error";
            }
        },
      async onDelete(){
        if(window.confirm("Are you sure you want to remove store? This cannot be undone.")){
          let apiKey = localStorage.getItem('aak') || this.$route.query.aak;
          const apiRoutePrefix = process.env.VUE_APP_API_URL;
          const deleteRoute = `${apiRoutePrefix}/api/delete?aak=${apiKey}&u=${this.selectedStore.email}&s=${this.selectedStore.storeNumber}`;


          try{
              let responseRaw = await fetch(deleteRoute,{
                  method: 'Post',
                  mode: 'cors',
              });

                if(!responseRaw.ok) {
                    this.error = r.statusText;
                    return;
                }
                else{
                  this.$emit('save');
                }

            }
            catch(ex){
              this.error = "Error";
            }
        

          this.$emit('delete');
          
        }
      },
    },
}
</script>

<template>
  <Transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <!-- <div class="modal-header">
            <slot name="header">default header</slot>
          </div> -->
          <div class="modal-body">
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedStore.storeNumber"
                       :disabled="!editable">
                <label>
                    Store Number
                </label>
              </div>
            <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedStore.storeName">
                <label>
                    Store Name
                </label>
              </div>
            <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedStore.parentAccount">
                <label>
                    Parent Store
                </label>
              </div>
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedStore.province">
                <label>
                    Province
                </label>
              </div>
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       v-model="selectedStore.email">
                <label>
                    Login E-mail
                </label>
              </div>
              <div class="form-floating mt-2">
                <input class="mt-1 mb-1 form-control"
                       :disabled="!editable"
                       v-model="selectedStore.password">
                <label>
                    Password
                </label>
              </div>
              <div class="form-check mt-2">
                <label class="form-check-label">
                    <input class="mt-1 mb-1 form-check-input"
                        type="checkbox"
                        :disabled="!editable"
                        v-model="selectedStore.isParentAccount">
                    Is Parent Account
                </label>
              </div>
          </div>
          <div class="modal-footer">
                <div class="alert alert-danger" v-if="error">{{error}}</div>
                  <button
                    class="modal-default-button"
                    @click="onDelete"
                    v-if="!editable">
                    Delete
                  </button>
                  <button
                    class="modal-default-button"
                    @click="onSave">
                    Save
                  </button>
                  <button
                    class="modal-default-button"
                    @click="$emit('close')">
                    Cancel
                  </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>