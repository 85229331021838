<template>
    <div v-if="!submitting" class="container-fluid">
        <nav class="navbar navbar-expand-sm bg-pcvt sticky-top"
             style="border-radius: 0px 0px 4px 4px">
            <div class="container-fluid">
                <div class="navbar-brand">
                    Exhibitor Invites
                </div>
                <div class="">
                    <div class="navbar-nav">
                        <a  :href="`${listExportUrl}&mode=all`"
                            role="button"
                            class="nav-link pe-auto">
                            Export All
                        </a>
                        <a :href="`${listExportUrl}&mode=non-reg`"
                            role="button"
                            class="nav-link pe-auto">
                            Export Non-registered
                        </a>
                        <a @click="exportRepsToCsvAsync"
                            role="button"
                            class="nav-link pe-auto">
                            Export Reps
                        </a>
                        <a @click="build"
                            role="button"
                            class="nav-link pe-auto">
                            Refresh
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        
        <h5 class="text-center mt-3 mb-3">
            Last refreshed: {{lastRefreshTime | moment('LL, LT')}}
        </h5>

        <div class="row">
            <div class="col">
                <div class="btn-group">
                    <button @click="showRegistered = !showRegistered"
                            class="btn btn-primary">
                        Registered
                    </button>
                </div>
            </div>
        </div>


        <div class="row sticky-top bg-white">
            <div class="col">
                <div>
                    <table class="table table-hover table-striped">
                        <thead class="sticky-top bg-white">
                            <tr>
                                <th>Name</th>
                                <th>Sponsor?</th>
                                <th>Registered</th>
                                <th>Mystery Special?</th>
                                <th  @click="sortBoothNumbers = !sortBoothNumbers">Booth #'s</th>
                                <th>Registered On</th>
                                <th>Last Updated</th>
                                <th>Form Link</th>
                                <th>Tractus Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in filteredList"
                                :key="item.code"
                                @click="onVendorClick(item)">

                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.sponsorCategory ? getSponsorCategory(item.sponsorCategory) : ''}}
                                </td>
                                <td class="text-center">
                                    {{item.regFormUrl ? '✅' : '❌'}}
                                </td>
                                <td>
                                    {{item.hasMysterySpecial ? 'Yes' : ''}}
                                </td>
                                <td> 
                                    {{ item.boothNumbers }}    
                                </td>
                                <td>
                                    {{item.registeredOn | moment("LL")}}
                                </td>
                                <td>
                                    {{item.lastUpdated | moment("LL LT")}}
                                </td>
                                <td>
                                    <a @click.stop
                                    :href="buildRegInviteUrl(item)"
                                    target="_blank">
                                        <template v-if="item.regFormUrl">
                                            Form
                                        </template>
                                        <template v-else>
                                            Register
                                        </template>
                                    </a>
                                </td>
                                <td>
                                    {{item.vendorCode}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        
            <!-- use the modal component, pass in the prop -->
            <modal @close="selectedInvite = null" 
                   @save="onSave"
                   v-if="selectedInvite"
                   :selectedInvite="selectedInvite">
            
            </modal>
        
        <loading-modal v-if="showExportModal">

        </loading-modal>


    </div>
    <div v-else class="d-flex h-100 w-100 justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5 class="mt-3">Loading, please wait...</h5>
      </div>
</template>
<script>
import Modal from './modal.vue'
import LoadingModal from './loadingModal.vue'


export default {
    components:{
        Modal,
        LoadingModal
    },
    data() {
        return {
            details: null,
            submitting: true,
            lastRefreshTime: null,
            errorLoad: false,
            selectedInvite: null,
            showExportModal: false,
            modal: null,
            showRegistered: false,
            sortBoothNumbers: false,
        };
    },

    computed: {
        listExportUrl() {
            return `${process.env.VUE_APP_API_URL}/api/stats/invites/vendors/export?aak=${localStorage.getItem('aak') || this.$route.query.aak}`;
        },

        registrationUrl() {
            const regUrl = process.env.VUE_APP_REG_URL;
            
            const finalLink = `${regUrl}?qr=${encodeURIComponent(`0:${this.details.storeNumber}`)}`

            return finalLink;
        },

        appUrl() {
            const appUrl = process.env.VUE_APP_PWA_URL;
            return appUrl;
        },

        summaryStats() {

            const totalRegistrationsComplete = this.details.invites.filter(x => x.regFormUrl).length;
            const totalRegistrationsIncomplete = this.details.invites.filter(x => !x.regFormUrl).length;
            const totalVendorsInvited = this.details.invites.length;

            return [
                {
                    Label: "# Exhibitors Registered",
                    Value: totalRegistrationsComplete
                },
                {
                    Label: "# Exhibitors Not Registered",
                    Value: totalRegistrationsIncomplete
                },
                {
                    Label: "# Exhibitors",
                    Value: totalVendorsInvited
                }
            ]
        },

        filteredList() {
            if(!this.details) {
                return [];
            }

            let toReturn = [...this.details.invites];

            if(this.showRegistered) {
                toReturn = toReturn.filter(x => x.regFormUrl);
            }
            console.log('SORT')

            if(this.sortBoothNumbers) {
                toReturn = toReturn.sort((a, b) => {
                    if(!a.boothNumbers && !b.boothNumbers
                        || a.boothNumbers == b.boothNumbers) {
                        return 0;
                    }

                    if(a.boothNumbers && !b.boothNumbers) {
                        return 1;
                    }

                    if(!a.boothNumbers && b.boothNumbers) {
                        return -1;
                    }

                    if(a.boothNumbers > b.boothNumbers) {
                        return 1;
                    }

                    if(a.boothNumbers < b.boothNumbers) {
                        return -1;
                    }

                    return 0;
                });
            }

            return toReturn;
        },
    },

    created() {
        // this.modal = new bootstrap.Modal(document.getElementById('loadingModal'), null);
        this.build();
    },

    beforeDestroy() {
    },

    methods: {
       async exportRepsToCsvAsync(){

            this.showExportModal = true;
            let apiKey = localStorage.getItem('aak') || this.$route.query.aak;
            let apiRoutePrefix = process.env.VUE_APP_API_URL;

            let vendorRoute = `${apiRoutePrefix}/api/stats/vendors/reps/export?aak=${apiKey}`;
            
            let repsRaw = await fetch(vendorRoute);
            if(!repsRaw.ok){
                alert("We're sorry. We couldn't export the reps. Please try again later.")
                return;
            }
            let reps = await repsRaw.json();
           
            let csvFile = "Tractus Vendor Code,Exhibitor Name,Representative Name,Title,E-Mail,Tractus Rep Code\n";
            for(let i =0; i < reps.length; i++){
                //VendorRepListModel (string VendorCode, string AttendeeCode, string ExhibitorName, string RepName, string Title, string Subtitle, string Email);
                let rep = reps[i];
                csvFile += `"${rep.vendorCode || ''}","${rep.exhibitorName || ''}","${rep.repName || ''}","${rep.title || ''}","${rep.email || ''}","${rep.attendeeCode || ''}"\r`;
            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);

                let now = new Date();
                let exportFileName = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;

                link.setAttribute("download", `export_vendor_reps_${exportFileName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.showExportModal = false;
            }

        },
        exportListToCsv(mode) {

            if(!mode) {
                mode = 'all';
            }

            let csvFile = 'Exhibitor Name,Sponor Level,Registered,Mystery Special Form,Registered On (UTC),Last Updated (UTC),SpinToWin Group,$100 Booth Prize,Registration Form Link,Emails\n';

            let items = this.details.invites;

            if(mode == 'non-reg') {
                items = items.filter(x => !x.regFormUrl);
            }

            for(let i = 0; i < items.length; i++) {
                let item = items[i];
                csvFile += `"${item.name}","${item.sponsorCategory ? this.getSponsorCategory(item.sponsorCategory) : ''}","${item.regFormUrl ? 'Yes' : ''}","${item.mysterySpecialUrl || ''}","${item.registeredOn || ''}","${item.lastUpdated || ''}","${item.hasSpinToWin ? item.hasSpinToWin : ''}","${item.has100BoothPrize ? 'Yes' : ''}","${this.buildRegInviteUrl(item)}","${item.emails || ''}"\r`;
            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);

                let now = new Date();
                let exportFileName = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;

                link.setAttribute("download", `export_vendor_invites_${mode}_${exportFileName}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }




        },

        onSave(){
            this.selectedInvite = null;
            this.build();
        },
        cloneObject(source) {
            if(source) {
                return JSON.parse(JSON.stringify(source));
            }

            return null;
        },
        onVendorClick(item){
            this.selectedInvite = this.cloneObject(item);
        },
        getSponsorCategory(code) {
            switch(code){
                case '1PLAT':
                    return 'Platinum';
                case '2GOLD':
                    return 'Gold';
                case '3SILV':
                    return 'Silver';
                case '4BRONZE':
                    return 'Bronze';
                default:
                    return code;
            }
        },

        buildRegInviteUrl(item) {
            const regUrl = process.env.VUE_APP_VREG_URL;

            if(item.regFormUrl) {

                const finalLink = `${regUrl}?r=${item.regFormUrl}`

                return finalLink;

            } else {

                const finalLink = `${regUrl}?rc=${item.code}&qrr=${encodeURIComponent(`01C7E89CF09242729DD50EDA40309D07:${item.code}`)},${encodeURIComponent(`7E081FA14C5D49E1929C370729DE7560:${item.name}`)}`

                return finalLink;

            }
            
        },

        async build() {

            let apiKey = localStorage.getItem('aak') || this.$route.query.aak;

            if(!apiKey) {
                this.$router.replace({path: '/'});
                return;
            }

            const apiRoutePrefix = process.env.VUE_APP_API_URL;

            const route = `${apiRoutePrefix}/api/stats/invites/vendors?aak=${apiKey}`;

            console.log(route);

            let responseRaw = await fetch(route);

            if(!responseRaw.ok) {
                localStorage.clear();
                alert("We're sorry. We couldn't load the analytics. Please try again later.")
                this.$router.replace({path: '/'});
                return;
            }

            localStorage.setItem('aak', apiKey);

            let response = await responseRaw.json();

            this.details = response;
            this.lastRefreshTime = new Date();
            this.submitting = false;
        },
    }
}
</script>
