<template>
    <div v-if="!submitting"
         class="container-fluid">
        <nav class="navbar bg-pcvt sticky-top"
             style="border-radius: 0px 0px 4px 4px">
            <div class="container-fluid">
                <div class="navbar-brand">
                    Store #{{details.storeNumber}}                    
                </div>
                <div>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a @click="signOut"
                               role="button"
                               class="nav-link pe-auto">
                                Sign Out
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <h1 class="text-center mt-5">Welcome, {{details.pharmacyName}}<span v-if="!details.isParentAccount">, Store #{{details.storeNumber}}</span></h1>
        <p class="text-center mb-5"
           v-if="!details.isParentAccount">
            Not store #{{details.storeNumber}}? <a role="button" class="color-primary" @click="signOut">Click here</a> to sign in as a different store.
        </p>
        <p v-else class="text-center mb-5">
            Not {{details.pharmacyName}}? <a role="button" class="color-primary" @click="signOut">Click here</a> to sign in as a different store.
        </p>

        <h4 class="text-center">Hotel Information</h4>
        <p class="text-center">
            <a href="https://tractus.blob.core.windows.net/content/PCVT2023/PC23TS_Hotel_Updated.pdf" target="_blank">Click here to download hotel registration information.</a>
        </p>

        <h4 class="text-center mt-3">
            If you are not attending the trade show, <a href="https://pcheast.sharepoint.com/:f:/s/members/EmiJpKsEXvNMsHh5nbefk7QBY1QiEcshYf5fFND4PKljKQ?e=GKV0Pm" target="_blank">please use this link to view order forms</a>.
        </h4>

        <template v-if="details.attendees.length > 0">
            <h2 class="text-center">Sign In to the Trade Show as a Registered Staff</h2>

            <div class="row mt-3 mb-5">
                <div class="col-md-4 col-sm-6 col-xs-12 mt-1"
                     v-for="attendee in details.attendees"
                     :key="attendee.Code">
                    <div class="card w-100 h-100">
                        <div class="card-header">
                            {{attendee.contactInfo.subTitle || "Staff (Update pending)"}}
                            <div class="badge bg-secondary"
                                 v-if="!attendee.contactInfo.subTitle"
                                 style="cursor: help"
                                 title="This staff member has been registered. The events team is finalizing some of their profile.">
                                ?
                            </div>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title">
                                {{attendee.firstName}} {{attendee.lastName}}
                            </h5>
                            <h6 class="card-subtitle" v-if="attendee.contactInfo.title">
                                {{attendee.contactInfo.title}}
                            </h6>
                            <div class="mt-3 d-flex">
                                <a :href="`${appUrl}/login?s=PCVT2023&a=${attendee.code}&p=${attendee.signInPin}&dl=true`"
                                    target="blank"
                                    class="btn btn-primary d-block">
                                    Sign In
                                </a>
                                <a v-if="attendee.regFormId"
                                    :href="`https://survey.tractus.ca/#/pp/PCVT2023/7458C5F268724DFEBC8A50DB8B4418D4?r=${attendee.regFormId}`"
                                    target="blank"
                                    class="btn btn-primary ms-1 d-block">
                                    Edit
                                </a>

                                <button class="btn btn-outline-danger d-block ms-auto"
                                        @click="requestDelete(attendee)">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12 mt-1">
                    <div class="card h-100">
                        <div class="card-header">
                            Add a Staff Member
                        </div>
                        <div class="card-body d-flex align-items-center justify-content-center">
                           
                            <a :href="registrationUrl"
                                @click="prepareForRefresh"
                                class="btn btn-outline-primary btn-lg"
                                target="_blank">
                                Click here to register a new staff member
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="d-flex align-items-center flex-column mt-3">
                <p class="text-center fs-5">You have not yet registered any staff for your store. Click the button below to register your first staff.</p>

                <a :href="registrationUrl"
                    @click="prepareForRefresh"
                    class="btn btn-primary btn-lg"
                    target="_blank">
                    Click here to register a new participant
                </a>

            </div>
        </template>
    </div>
    <div v-else class="d-flex h-100 w-100 justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5 class="mt-3">Loading, please wait...</h5>
      </div>

      
</template>
<script>
export default {
    data() {
        return {
            details: null,
            submitting: true,
            refreshOnVisChange: false,
        };
    },

    computed: {
        registrationUrl() {
            const regUrl = process.env.VUE_APP_REG_URL;
            
            const finalLink = `${regUrl}?qr=${encodeURIComponent(`0:${this.details.storeNumber}`)}`

            return finalLink;
        },

        appUrl() {
            const appUrl = process.env.VUE_APP_PWA_URL;
            return appUrl;
        },

        apiUrl() {
            return process.env.VUE_APP_API_URL;
        },
    },

    created() {
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        this.build()
    },

    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
    },

    methods: {
        async requestDelete(attendee) {
            let confirmation = confirm(`Are you sure you want to delete registration for ${attendee.firstName} ${attendee.lastName}? This is permanent and cannot be undone.`);

            if(!confirmation) {
                return;
            }

            const apiRoutePrefix = process.env.VUE_APP_API_URL;
            let apiKey = localStorage.getItem('apikey');
            let username = localStorage.getItem('user');

            const route = `${apiRoutePrefix}/api/store/deleteregistration?ac=${attendee.code}&apikey=${encodeURIComponent(apiKey)}&username=${username}`;

            let responseRaw = await fetch(route, {
                method: 'delete'
            });

            if(!responseRaw.ok) {
                alert("Could not delete registration. Please try again later.");
                return;
            }

            this.build();
        },


        onVisibilityChange(e) {
            if(document.visibilityState != "visible") {
                return;
            }

            if(!this.refreshOnVisChange) {
                return;
            }

            this.refreshOnVisChange = false;
            this.build();
        },

        signOut() {
            localStorage.clear();
            this.$router.replace({path: '/'});
        },

        prepareForRefresh() {
            this.refreshOnVisChange = true;
        },

        async build() {
            let apiKey = localStorage.getItem('apikey');
            let username = localStorage.getItem('user');

            if(!apiKey || !username) {
                this.$router.replace({path: '/'});
                return;
            }

            const apiRoutePrefix = process.env.VUE_APP_API_URL;

            const route = `${apiRoutePrefix}/api/welcome?username=${username}&apikey=${encodeURIComponent(apiKey)}`;

            let responseRaw = await fetch(route);

            if(!responseRaw.ok) {
                localStorage.removeItem('apikey');
                this.$router.replace({path: '/'});
                return;
            }

            let response = await responseRaw.json();

            this.details = response;

            this.submitting = false;
        },
    }
}
</script>
