<template>
    <div ref="output">

    </div>
</template>
<script>
const marked = require('marked')

export default {
    props: ['source'],

    watch: {
        source() {
            this.rebuildOutput();
        }
    },

    methods: {
        rebuildOutput() {
            let container = this.$refs.output;
            console.log("Source: ", this.source);
            let content = marked(this.source || "");
            container.innerHTML = content;            
        }
    },

    mounted() {
        this.rebuildOutput();
    }
}
</script>