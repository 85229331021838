<template>
    <div class="vh-100 d-flex align-items-center justify-content-center">
        <div style="max-width: 400px; width: 100%">
            <img src="https://tractus.blob.core.windows.net/content/PCVT2023/logos/2023-TS_Square-Logo.png"
                  class="img-fluid">

            <form @submit.prevent
                class="mt-3">
                <div class="form-floating mb-3">

                    <input type="email"
                        class="form-control"
                        placeholder="name@email.com"
                        :disabled="submitting"
                        v-model="username">

                    <label>Username - Store E-Mail Address</label>
                </div>

                <div class="form-floating mb-3">
                    
                    <input type="password"
                        class="form-control"
                        :disabled="submitting"
                        placeholder="Password"
                        v-model="password">

                    <label>Password</label>
                </div>

                <div class="d-grid">
                    <button class="btn btn-primary"
                            @click="attemptLogin"
                            v-if="!submitting">
                        Sign In
                    </button>
                </div>
            </form>

            <p class="mt-4 text-center">If you are interested in attending our show, please contact <a href="mailto:tradeshow@pharmachoice.com">tradeshow@pharmachoice.com</a>.</p>

            <div class="alert alert-danger mt-3"
                v-if="signInFailed">
                <h4>Could not sign in.</h4>
                <p v-if="!additionalInfo">
                    Please double-check your e-mail address and password then try again.
                </p>
                <p v-else>
                    {{additionalInfo}}
                </p>
            </div>

            <div class="text-center mt-2">
                <div class="spinner-border" 
                        role="status"
                        v-if="submitting">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {

    },

    data() {
        return {
            username: '',
            password: '',
            submitting: false,
            initializing: true,
            signInFailed: false,
            additionalInfo: null,
        };
    },

    created() {
        this.build();

        try {
            let query = this.$route.query;

            if(query.u) {
                this.username = query.u;
            }

            if(query.p) {
                this.password = escape(atob(decodeURIComponent(query.p)));
            }

        } catch {

        }
    },

    computed: {
        pwEncode() {
            return this.password ?
                encodeURIComponent(btoa(this.password))
                : '';

        }
    },

    methods: {

        async build() {
            if(this.$route.query.u) {
                return;
            }
            
            let username = localStorage.getItem('user');

            if(username) {
                this.username = username;

                let apiKey = localStorage.getItem('apikey');

                if(apiKey) {
                    this.$router.replace({path: '/home'});
                    return;
                }
            }

            this.initializing = false;
        },

        async attemptLogin() {
            this.signInFailed = false;
            this.username = this.username.toLowerCase();
            this.submitting = true;

            const apiRoutePrefix = process.env.VUE_APP_API_URL;

            const route = `${apiRoutePrefix}/api/login?name=${this.username}&pw=${this.password}`;

            try {

                let responseRaw = await fetch(route);

                console.log('Fetch result: ', responseRaw);
                if(!responseRaw.ok) {
                    this.signInFailed = true;
                    this.submitting = false;
                    return;
                }

                let response = await responseRaw.json();

                localStorage.setItem('apikey', response.apiKey);
                localStorage.setItem('user', this.username);
                console.log(response);
                
                this.$router.replace({path: '/home'})

            } catch (ex) {
                this.signInFailed = true;
                this.submitting = false;
                console.error(ex);
                this.additionalInfo = "We could not connect to the server. Please try again later.";
            }
        }
    }
}
</script>
