<template>
    <div v-bind:style="{ height: '100%', width: '100%' }">
        <canvas class="canvas-local" style="height: 100%; width: 100%" ref="cv" /> 
        <img class="barcode-target" style="height: 100%; width: 100%" ref="img">
    </div>
</template>
<script>
import bwipjs from 'bwip-js'

export default {
    props: ['code', 'mode', 'padding'],
    mounted() {
        this.onRender();
    },

    methods: {
        onRender() {
            var vm = this;
            var canvas = this.$refs.cv;
            bwipjs.toCanvas(canvas, {
                bcid: 'qrcode',
                scale: 1,
                text: vm.code,
            });

            this.$refs.img.src = canvas.toDataURL();
        }
    },

    watch: {
        code() {
            this.onRender();
        },
        mode() {
            this.onRender();
        }
    }
}
</script>
<style>
.barcode-target {
    image-rendering: pixelated;
}
/* BUG: This style applies to the canvas element in Lua */
.canvas-local {
    display: none;
    image-rendering: optimizeSpeed;             /* Older versions of FF          */
    image-rendering: -moz-crisp-edges;          /* FF 6.0+                       */
    image-rendering: -webkit-optimize-contrast; /* Safari                        */
    image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
    image-rendering: pixelated;                 /* Awesome future-browsers       */
    -ms-interpolation-mode: nearest-neighbor;   /* IE                            */
}
</style>
